import { Link } from 'gatsby'
import React from 'react'
import './blogCard.scss'
export const BlogCards = ({
  imageUrl,
  date,
  title,
  description,
  contentfulId,
}) => {
  const defaultData = {
    date: '10/09/2022',
    title: 'How to create surveys which will produce maximum results',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat id enim ac egestas nibh ac tellus. Vestibulum tempor non .....',
  }
  return (
    <div className="blod-card-container">
      <div className="row mt-5 mx-auto-lr">
        <img
          src={
            imageUrl ??
            `https://unsplash.com/photos/qZFsarch8iM/download?ixid=MnwxMjA3fDB8MXxhbGx8NHx8fHx8fDJ8fDE2NzM4NzIyMDQ&force=true&w=2400`
          }
          className="br-10 img-container"
          alt="card_img"
        />
      </div>

      <div className="col mt-5 mx-auto-lr p-5 ">
        <h5 className="mt-2 mb-2">{date ?? defaultData.date}</h5>
        <span className="mt-2 ft-bl">{title ?? defaultData.title}</span>
        <p className="ellipsis-enabled">
          {description ?? defaultData.description}
        </p>
      </div>
      <div className="row mt-5 p-5 ">
        <Link
          // to={`/blog/blogdetails`}
          to={`/in-progress`}
          state={{ contentfulId }}
          className="text-pink"
        >
          Read More{' '}
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 4C0.723858 4 0.5 4.22386 0.5 4.5C0.5 4.77614 0.723858 5 1 5V4ZM11.3536 4.85355C11.5488 4.65829 11.5488 4.34171 11.3536 4.14645L8.17157 0.964466C7.97631 0.769204 7.65973 0.769204 7.46447 0.964466C7.2692 1.15973 7.2692 1.47631 7.46447 1.67157L10.2929 4.5L7.46447 7.32843C7.2692 7.52369 7.2692 7.84027 7.46447 8.03553C7.65973 8.2308 7.97631 8.2308 8.17157 8.03553L11.3536 4.85355ZM1 5H11V4H1V5Z"
              fill="#D75281"
            />
          </svg>
        </Link>
      </div>
    </div>
  )
}
