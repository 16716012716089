import { useStaticQuery, graphql } from 'gatsby'

export const BlogData = () => {
  let queryData = useStaticQuery(graphql`
    query myBlogContents {
      allContentfulBlog {
        nodes {
          authorsName
          imageUrl
          subDescription
          contentful_id
          authorsProfession
          blogCategory
          blogTitle
          content {
            raw
          }
          createdAt
          id
        }
      }
    }
  `)

  return queryData
}
