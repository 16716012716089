import React from 'react'
import { BlogCards } from '../../components/Card/BlogCards/BlogCards'
import Footer from '../../components/Footer'
import { CenteredHero } from '../../components/Hero/CenteredHero'
import SampleSizeCTA from '../../Contentful/SampleSize'
import { QueryGraphql } from '../../utils/graphQlQuery'
import richTextRenderer from '../../utils/richTextRenderer'
import './blog.scss'
import { BlogData } from '../../utils/GraphQL/blogQueryData'

const Blog = () => {
  let data = QueryGraphql()
  const blogData = BlogData()
  const {
    allContentfulBlog: { nodes: blogNodes },
  } = blogData
  const {
    allContentfulHero: { nodes },
  } = data


  // const mainData = desc.find((subject) => subject.title === 'Akta Pricing')
  const mainData = nodes
    .filter((val) => val.header === 'Our Blog')
    .filter((val) => {
      const { desc: mainDesc } = val
      return mainDesc ?? val
    })

  const { header, mainDescription, title: info, backgroundColor } = mainData[0]

  const pageDescription = mainDescription.join('')

  const headerData = {
    pageTitle: 'Our Blog',
    pageInfo:
      'Our in-depth tips and advice for creating the best in class market research',
    pageDescription: `A collection of articles, insights, provocations and trends on the latest in Data, Tech, and Marketing`,
    defaultBackground: 'bg-light-orange',
    h1Position: `mx-auto w-45 mt-2 mb-2`,
    pageDescriptionPosition: 'mx-auto w-38 ft-sz-10',
  }
  return (
    <div className="blog-container">
      <div className="row w-80 mx-auto">
        <CenteredHero
          background={backgroundColor ?? headerData.defaultBackground}
          pageDescription={pageDescription ?? headerData.pageDescription}
          pageInfo={info ?? headerData.pageInfo}
          pageTitle={header ?? headerData.pageTitle}
          h1Position={headerData.h1Position}
          pageDescriptionPosition={headerData.pageDescriptionPosition}
        />
      </div>

      <div className="col mobile-w-70 w-70 mx-auto mt-5 ">
        <div className="grid-col">
          {blogNodes &&
            blogNodes.map((val, ind, arr) => {
              const {
                blogTitle,
                createdAt,
                id,
                content,
                subDescription,
                contentful_id,
              } = val
              const newDate = new Date(createdAt)
              return (
                <BlogCards
                  date={newDate.toLocaleDateString()}
                  title={blogTitle}
                  description={subDescription}
                  contentfulId={contentful_id}
                />
              )
            })}
        </div>
        {/* {blogNodes &&
          blogNodes.map((val, ind, arr) => {
            if (arr.length % 3 <= 2) {
              return 'In to 3 row'
            } else {
              return 'In the 4 row'
            }
          })} */}
        {/* <div className="row sp-bt">
          <BlogCards />
          <BlogCards />
          <BlogCards />
        </div>
        <div className="row sp-bt mt-5">
          <BlogCards />
          <BlogCards />
          <BlogCards />
        </div>
        <div className="row sp-bt mt-5">
          <BlogCards />
          <BlogCards />
          <BlogCards />
        </div> */}
      </div>
      <div className="mt-5">
        <SampleSizeCTA />
        <Footer />
      </div>
    </div>
  )
}

export default Blog
